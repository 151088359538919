:root {
	--color-primary: #335AA6;
	--color-primary-light: #89ABD9;
	--color-primary-dark: #18358C;
	--color-grey-light-1: #faf9f9;
	--color-grey-light-2: #f4f2f2;
	--color-grey-light-3: #f0eeee;
	--color-grey-light-4: #ccc;
	--color-grey-dark-1: #404040;
	--color-grey-dark-2: #777;
	--color-grey-dark-3: #999;
	--color-red: #A52631;
	--color-green: #207245;
	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
	--line: 1px solid var(--color-grey-light-2);
}
* {
	margin: 0 ;
	padding: 0 ;
}
*,
*::before,
*::after {
	box-sizing: inherit;
}
html {
	box-sizing: border-box;
	font-size: 62.5%; 
}
@media only screen and (max-width: 68.75em) {
	html {
		font-size: 50%;
	} 
}
body {
	font-weight: 400;
	line-height: 1.6;
	color: var(--color-grey-dark-2);
	background-color: var(--color-grey-light-3);
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	font-family: 'Public Sans', sans-serif;
}

/* ANIMATION */
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

/* APP */
.app {
	min-height: 100vh;
	width: 100%;
}
.loader {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-primary-dark);
}
.loader i {
	-webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
	animation:spin 4s linear infinite;
	font-size: 4.5rem;
	color: #fff;
}
header {
	width: 100%;
    padding: 0 2rem;
    display: flex;
	height: 6rem;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}
.header__container {
	display: flex;
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.header__container picture {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.header__container picture img {
	height: 4rem;
}
.header__container ul {
	flex: 1;
	display: flex;
}
.header__container ul a {
	display: flex;
	padding: 1rem;
	align-items: center;
	justify-content: center;
	flex: 1;
	text-decoration: none;
	font-size: 1.4rem;
	color: #fff;
	text-transform: uppercase;
	position: relative;
}
.header__container ul a span {
	position: relative;
}
.header__container ul a::before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0;
	background-color: var(--color-primary-dark);
	transition: all .5s;
}
.header__container ul a:hover::before,
.header__container ul a.active::before {
	height: 100%;
}
footer {
	display: flex;
	background-color: var(--color-grey-dark-1);
}
.footer__container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.footer__top {
	padding: 4.5rem 0;
	display: flex;
	border-bottom: var(--line);
}
.footer__top ul {
	flex: 0 0 20%;
	display: flex;
	flex-direction: column;
}
.footer__top ul h3 {
	font-size: 1.4rem;
	text-transform: uppercase;
	color: #fff;
}
.footer__top ul a {
	font-size: 1.4rem;
	margin: 1rem;
	padding: .5rem;
	color: #fff;
	text-decoration: none;
}
.footer__bottom {
	display: flex;
}
.footer__bottom-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.footer__bottom-container p {
	color: #fff;
	font-size: 1.4rem;
	padding: 2rem;
}
.app main {
	flex: 1;
	overflow-y: auto;
}
.app .sidenav {
	overflow-y: auto;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	transition: all .5s;
	width: auto;
}
.sidenav img {
	width: 4rem;
	margin: 1rem auto;
}
.app .sidenav ul {
	display: flex;
	flex-direction: column;
	list-style: none;
}
.app .sidenav ul a {
	padding: 1.3rem;
	color: var(--color-primary);
	font-size: 1.4rem;
	text-decoration: none;
	text-transform: uppercase;
	display: flex;
	align-items: center;
}
.app .sidenav ul a:hover,
.app .sidenav ul a.active {
	background-color: var(--color-primary-dark);
	color: #fff;
}
.app .sidenav ul a span {
	margin-left: 1rem;
}
.app .sidenav ul.close a span {
	display: none;
}
.app__container {
  position: relative;
}
.app__container > button {
	position: absolute;
	top: .5rem;
	left: 0;
	border: none;
	background-color: var(--color-primary);
	padding: .25rem;
	color: #fff;
	cursor: pointer;
	outline: none;
	border-top-right-radius: .5rem;
	border-bottom-right-radius: .5rem;
	opacity: .6;
	transition: all .5s;
	display: flex;
}
.app__container > button:hover {
	opacity: 1;
}
.input-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	font-size: 1.4rem;
	text-transform: uppercase;
	color: var(--color-primary);
}
.input-container input {
	padding: 1rem;
	outline: none;
	border: none;
	background-color: rgba(255,255,255,.8);
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.06);
	margin-top: 1rem;
}
.input-container button {
	padding: 1rem;
	border: none;
	background-color: var(--color-primary);
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	outline: none;
}
table {
	width: 100%;
	border-collapse: collapse;
	font-size: 1.2rem;
}
table th {
	background-color: var(--color-primary);;
	padding: 1rem;
	text-transform: uppercase;
	color: #fff;
	font-weight: 600;
}
table td {
	color: var(--color-grey-dark-1);
	padding: 1rem;
}
table tr:nth-of-type(even) td {
	background-color: rgba(255,255,255,.6);
}
table td a {
	color: var(--color-primary);
	text-decoration: none;
}
.cover {
	background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, rgba(0,0,0,.8) 50%);
	height: 80vh;
	position: relative;
	display: flex;
}
.cover__container {
	width: 100%;
	max-width: 100rem;
	margin: auto;
	display: flex;
}
.cover__container .title {
	flex: 0 0 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 2.5rem;
}
.cover__container .title h1 {
	font-size: 4.5rem;
	text-transform: uppercase;
	color: var(--color-grey-dark-1);
}
.cover__container .title p {
	font-size: 2rem;
	color: var(--color-grey-dark-3);
}
.cover__container picture {
	flex: 1;
}
.cover__container picture img {
	width: 100%;
}

/* HOME */
.home__about {
	display: flex;
}
.home__about-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
	padding: 9rem 0;
}
.home__about-container h3 {
	flex: 1;
	font-size: 4.5rem;
	color: var(--color-grey-dark-1);
	text-transform: uppercase;
	letter-spacing: 3px;
}
.home__about-container p {
	flex: 1;
	font-size: 1.4rem;
	text-align: justify;
}
.home__enterprises {
	display: flex;
	background-color: #fff;
}
.home__enterprises-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	padding: 9rem 0;
	display: flex;
	flex-direction: column;
}
.home__enterprises-container h3 {
	font-size: 2.5rem;
	color: var(--color-primary-dark);
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 3px;
	border-bottom: 2px solid var(--color-primary-dark);
}
.home__enterprises-container ul {
	display: flex;
	justify-content: space-around;
	margin-top: 9rem;
}
.home__enterprises-container ul a {
	display: flex;
	align-items: center;
	justify-content: center;
}
.home__enterprises-container ul a img {
	width: 100%;
	max-width: 20rem;
}
.home__enterprises-container > a {
	padding: 1rem 2.5rem;
	background-color: var(--color-primary);
	color: #fff;
	font-size: 1.4rem;
	text-decoration: none;
	text-transform: uppercase;
	margin: 4.5rem auto;
}


/* PROMO-REPORTS */
.promo-reports {
	padding: 9rem 2.5rem;
}